import React from "react";
import Header from "../components/Header";

const RulesPage = () => {
  return (
    <>
      <Header
        title={"Game Rules"}
        subtitle={
          "These rules represent honour, integrity and fairness in the games"
        }
      />
      <div className="columns">
        <div className="column"></div>
        <div className="column is-two-thirds">
          <section className="section pt-0">
            <div className="content has-text-centered">
              <hr></hr>
              <h2 className="subtitle">Rule #1</h2>
              <p>
                Points are only earned from activities completed while a game is
                in progress.
              </p>
              <hr></hr>

              <h2 className="subtitle">Rule #2</h2>
              <p>
                Points are only earned from activities completed while you are a
                participant in a game.
              </p>
              <hr></hr>

              <h2 className="subtitle">Rule #3</h2>
              <p>Activities must only be submitted once.</p>
              <hr></hr>

              <h2 className="subtitle">Rule #4</h2>
              <p>Activities must be completed using a bike</p>
              <hr></hr>

              <h2 className="subtitle">Rule #5</h2>
              <p>Activities must have their “Sport Type” labelled correctly</p>
              <hr></hr>

              <h2 className="subtitle">Rule #6</h2>
              <p>
                You may only enter a game once, using a single Gradient Games
                account
              </p>
            </div>
            {/* Add more sections and FAQ items as needed */}
          </section>
        </div>
        <div className="column"></div>
      </div>
    </>
  );
};

export default RulesPage;
