import React from "react";
import Lottie from "react-lottie";
import RoundImage from "./Avatar";
import trophyAnimation from "../assets/confetti_lottie.json"; // Path to your lottie animation file

const WinnerSection = ({ winnerName, winnerAvatarUrl }) => {
  // Options for the Lottie animation
  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: trophyAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="has-text-centered">
      <div className="content">
        <h4>Congratulations to our winner</h4>
        <div className="trophy-animation">
          <Lottie
            options={animationOptions}
            height={200} // Adjust height as needed
            width={200} // Adjust width as needed
            isStopped={false} // Play animation
            speed={0.5} // Adjust playback speed as needed
          />
        </div>
        <h3>{winnerName}</h3>
        <div className="block">
          <RoundImage
            imageUrl={winnerAvatarUrl}
            alt="Avatar image fetched from Strava"
          />
        </div>
        
      </div>
    </div>
  );
};

export default WinnerSection;
