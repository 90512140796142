import React from "react";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import { UserContext } from "../context/UserContext";
import "../App.css";

import Header from "../components/Header";
import TableEfforts from "../components/TableEfforts";
import SegmentDetails from "../components/SegmentDetails";
import TablePlayerStats from "../components/TablePlayerStats";
import JoinGameModal from "../components/ModalJoinGame";
import JoinSuccessModal from "../components/ModalJoinSuccess";
import InsufficientFundsModal from "../components/ModalInsufficientFunds";
import WinnerSection from "../components/WinnerSection";
import LoginRegisterModal from "../components/ModalLoginRegister";
import StravaConnectModal from "../components/ModalStravaRequired";

export default () => {
  const { token, user = {}, isUserLoaded, fetchUser } = useContext(UserContext);
  const [game, setGame] = useState();
  const [gameEnded, setGameEnded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { slug } = useParams();
  const [activeJoinModal, setActiveJoinModal] = useState(false);
  const [activeJoinSuccessModal, setActiveJoinSuccessModal] = useState(false);
  const [activeDepositModal, setActiveDepositModal] = useState(false);
  const [activeLoginModal, setActiveLoginModal] = useState(false);
  const [activeStravaModal, setActiveStravaModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getGame = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(`/api/games/${slug}`, requestOptions);

    if (!response.ok) {
      console.log("Game didn't load");
    } else {
      const gameData = await response.json();
      setGame(gameData);
      setLoaded(true);
      if (moment().diff(gameData.end_date) > 0) {
        setGameEnded(true);
      }
    }
  };

  const GameStatusTag = () => {
    if (game.is_active) {
      return (
        <div className="tag is-primary is-light has-text-centered">
          THIS GAME IS ACTIVE
        </div>
      );
    } else if (moment().diff(game.start_date) < 0) {
      return (
        <div className="tag is-warning is-light has-text-centered">
          THIS GAME HAS NOT YET STARTED
        </div>
      );
    } else {
      return (
        <div className="tag is-danger is-light has-text-centered">
          THIS GAME HAS ENDED
        </div>
      );
    }
  };

  const GameStats = ({ game }) => {
    return (
      <>
        <div>
          <div>Players in the Game: {game.user_links.length}</div>
          {/* <div>
            Players to be Refunded:{" "}
            {Math.round(game.user_links.length * game.no_sweats)}
          </div> */}
          <div>Prize Value (so far): £{game.prize.toFixed(2)} </div>
          <div className="content is-small">⚠️ NOT REAL CASH IN BETA ⚠️</div>
          {/* TODO In theory, at low player counts, by "rounding" rather than "flooring", we may be losing cash more quickly */}
          {/* <div>Total Points earned: {game.points}</div> */}
        </div>
      </>
    );
  };

  const handleJoinModal = () => {
    setActiveJoinModal(!activeJoinModal);
    fetchUser();
  };

  const handleJoinSuccessModal = () => {
    setActiveJoinSuccessModal(!activeJoinSuccessModal);
  };

  const handleDepositModal = () => {
    setActiveDepositModal(!activeDepositModal);
  };

  const handleLoginModal = () => {
    setActiveLoginModal(!activeLoginModal);
  };

  const handleStravaConnectModal = () => {
    setActiveStravaModal(!activeStravaModal);
  };

  const horizontalRuleStyle = {
    height: "5px",
    border: "none",
  };

  const sectionHeadingStyle = {
    fontSize: 24,
    // color: "#4a54f1",
    // textAlign: "center",
    padding: "30px",
  };

  const buttonContentStyle = {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden", // Ensure text does not extend beyond button boundaries
    paddingTop: "3px", // Add padding to the top
    paddingBottom: "3px", // Add padding to the bottom
  };

  const buttonStyle = {
    height: "auto", // Allow button height to adjust based on content
  };

  useEffect(() => {
    getGame();
  }, [user]);

  return (
    <>
      {loaded && isUserLoaded ? (
        <>
          <JoinGameModal
            game={game}
            active={activeJoinModal}
            handleModal={handleJoinModal}
            token={token}
            setErrorMessage={setErrorMessage}
            handleSuccessModal={handleJoinSuccessModal}
          />
          <InsufficientFundsModal
            game={game}
            active={activeDepositModal}
            handleModal={handleDepositModal}
            token={token}
            setErrorMessage={setErrorMessage}
          />
          <JoinSuccessModal
            active={activeJoinSuccessModal}
            handleModal={handleJoinSuccessModal}
          />
          <LoginRegisterModal
            isOpen={activeLoginModal}
            onClose={handleLoginModal}
          />
          <StravaConnectModal
            active={activeStravaModal}
            handleModal={handleStravaConnectModal}
          />
          {/* <Header title={game.name} /> */}

          <section className="section">
            <div className="columns">
              <div className="column"></div>
              <div className="column is-two-thirds">
                <div className="columns">
                  <div className="column is-two-thirds">
                    <div className="content">
                      <h1 className="title">{game.name}</h1>
                    </div>
                    <div className="block">
                      <div className="content is-small">
                        {moment(game.start_date).format("Do MMM YYYY")} -{" "}
                        {moment(game.end_date).format("Do MMM YYYY")}{" "}
                      </div>
                    </div>
                    <div className="block">
                      <GameStatusTag />
                    </div>
                  </div>
                  <div className="vertical-divider"></div>{" "}
                  {/* Vertical divider */}
                  <div className="column">
                    <GameStats game={game} />
                  </div>
                </div>
                <hr className="is-divider" />
                <div className="has-text-centered">
                  {/* FIXME need to handle presenting this page if user is NOT logged in */}
                  {!gameEnded ? (
                    user.game_links &&
                    user.game_links
                      .map((obj) => obj.game.id)
                      .indexOf(game.id) !== -1 ? (
                      <>
                        <p>
                          You are currently playing in this game and have
                          collected{" "}
                          <strong>
                            {
                              user.game_links[
                                user.game_links
                                  .map((obj) => obj.game.id)
                                  .indexOf(game.id)
                              ].points
                            }{" "}
                            points{" "}
                          </strong>
                          so far
                        </p>
                      </>
                    ) : (
                      <>
                        <button
                          className="button is-success is-fullwidth"
                          onClick={
                            token
                              ? // TODO Handle custom price amounts
                                !user.strava_id
                                ? handleStravaConnectModal
                                : user.credits >= game.credits
                                ? handleJoinModal
                                : handleDepositModal
                              : handleLoginModal
                          }
                          style={buttonStyle}
                        >
                          <div style={buttonContentStyle}>
                            <span>Join Now</span>
                            {/* <br /> */}
                            <span className="content is-small">
                              USES {game.credits} GAME CREDITS
                            </span>
                          </div>
                        </button>
                        {token && user ? (
                          <p className="content is-small has-text-centered mt-2">
                            You currently have {user.credits} credits
                          </p>
                        ) : null}
                      </>
                    )
                  ) : null}
                </div>
                <section className="block">
                  {gameEnded && game.winner ? (
                    <>
                      <WinnerSection
                        winnerName={
                          game.winner.strava_first_name +
                          " " +
                          game.winner.strava_last_name
                        }
                        winnerAvatarUrl={game.winner.strava_avatar_url}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </section>
                <hr className="is-divider" />
                <div className="content">
                  <div className="block has-text-centered">
                    <h2>Ride to earn points. Earn points to win.</h2>
                    <div><em>Earn 1 point for every 1km travelled and {(1/game.climb_points).toFixed(1)}m climbed</em></div>
                  </div>
                  <div className="block grid">
                    <div className="cell">
                      <div className="notification">
                        <h5 className="">CASH PRIZE (£{game.prize})</h5>
                        <div className="content is-small">⚠️ NOT REAL CASH IN BETA ⚠️</div>
                        <p>
                          One player will be drawn <strong>at random</strong> to win the cash
                          prize
                        </p>
                        <p className="content is-small">
                          <strong>
                            ✨ The more points you have, the better your odds ✨
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="cell">
                      <div className="notification">
                        <h5 className="">
                          FREE ENTRY INTO NEXT GAME
                        </h5>
                        <p>
                          The top 40% of points scorers will get auto-enrolled into the next game
                        </p>
                        <p className="content is-small">
                          <strong>
                            ✅ If you've already joined the next game, you'll get a credit refund ✅
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <h4>GAME DESCRIPTION</h4>
                  <p className="is-italic">{game.description}</p>
                </div>
                {/* <div className="notification is-info is-light">
                  <strong>Game rules:</strong>
                  <ul>
                    <li>
                      Earn 1 point for every km you ride (1 point per 2km for
                      Ebike rides)
                    </li>
                    <li>
                      Only activities <strong>completed after</strong> joining,
                      count
                    </li>
                  </ul>
                </div> */}
                <hr className="is-divider" />
                <section>
                  <TablePlayerStats gameId={game.id} userId={user ? user.id : null}></TablePlayerStats>
                </section>
              </div>
              <div className="column"></div>
            </div>
          </section>
        </>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};
