import React from "react";
import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate, Link } from "react-router-dom";
import Header from "../components/Header";
import LoginRegisterPage from "../components/LoginRegister";
// import HowToPlay from "../components/HowToPlay";
import strava_connect from "../assets/btn_strava_connectwith_orange.png";
import stravaAuthUrl from "../components/stravaAuthUrl";
import InfoModal from "../components/modalInfo";

export default () => {
  const { user, token, isUserLoaded } = useContext(UserContext);
  const [activeStravaModal, setActiveStravaModal] = useState(false);

  const InfoBox = ({ title, content, cta }) => {
    return (
      <div className="notification">
        <h3>{title}</h3>
        <p>
          <em>{content}</em>
        </p>
        {cta}
      </div>
    );
  };

  const handleStravaModal = () => {
    setActiveStravaModal(!activeStravaModal);
  };

  return (
    <>
      <InfoModal
        isOpen={activeStravaModal}
        onClose={handleStravaModal}
        text={
          "The Gradient Games uses your activities from Strava to calculate how many points you've earned in a game. Each point you earn increases your chances of winning the cash prize"
        }
      />
      <div className="columns">
        <div className="column"></div>
        <div className="column is-two-thirds">
          {!token ? (
            <>
              <Header
                title="Welcome to The Gradient Games"
                subtitle="Regular cycling games, with real rewards, that favour the active rider"
              />
              <div>
                <LoginRegisterPage />
              </div>
            </>
          ) : (
            <>
              {isUserLoaded ? (
                !user.strava_id ? (
                  <>
                    <Header
                      title="Welcome to The Gradient Games"
                      subtitle={
                        "To play in The Gradient Games, connect Your Strava account and earn points from your activities"
                      }
                    />
                    <div className="content has-text-centered">
                      <a href={stravaAuthUrl}>
                        <img src={strava_connect} alt={"Connect with Strava"} />
                      </a>
                    </div>
                    <div className="has-text-centered">
                      <a
                        className="content is-small"
                        onClick={handleStravaModal}
                      >
                        Why do you need this?
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <Header
                      title={`Hey, ${user.strava_first_name}, nice to see you`}
                      subtitle={
                        "Join a game, get moving and earn points for the chance to win cash"
                      }
                    />
                    <div className="container">
                      <div className="block m-4 mb-6">
                        <div className="grid content has-text-centered">
                          <div className="cell">
                            <InfoBox
                              title="NEW HERE?"
                              content="If you've just arrived here, or want to learn more about the games, check out how it all works"
                              cta={
                                <Link className="button is-info" to={"/how"}>
                                  How it works
                                </Link>
                              }
                            />
                          </div>
                          <div className="cell">
                            <InfoBox
                              title="READY TO PLAY?"
                              content="If you're ready to roll and want to see which games are available to play"
                              cta={
                                <Link
                                  className="button is-primary"
                                  to={"/games"}
                                >
                                  View games
                                </Link>
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <div className="content has-text-centered">
                  <em>Loading...</em>
                </div>
              )}
            </>
          )}
        </div>
        <div className="column"></div>
      </div>
    </>
  );
};
