import React from "react"
import { useState, useContext } from "react";
import { UserContext } from "../context/UserContext";


const ToggleSwitch = ({ initialChecked, endpoint }) => {
    const { token } = useContext(UserContext);
    const [checked, setChecked] = useState(initialChecked);
    
    const handleToggle = async () => {
      try {
        const requestOptions = {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ auto_enrol: !initialChecked })
          };
          const response = await fetch(`/api/${endpoint}`, requestOptions);
        if (response.status === 200) {
          setChecked(!checked);
        } else {
          console.error('Failed to update the toggle state');
        }
      } catch (error) {
        console.error('Error while making PUT request', error);
      }
    };
  
    return (
      <div className="field">
        <input
          id="switchRoundedDefault"
          type="checkbox"
          name="switchRoundedDefault"
        //   className="switch is-rounded is-info"
          checked={checked}
          onChange={handleToggle}
        />
      </div>
    );
  };
  
  export default ToggleSwitch;