import React from "react";

const WaysToWin = ({prize, credits}) => {
  return (
    <div className="content has-text-centered">
      <div className="block">
        <h2>2 Ways to Win</h2>
      </div>
      <div className="block grid">
        <div className="cell">
          <div className="notification">
            <h5 className="">CASH PRIZE → {" "} {prize ? (null) : ("Random Draw")}</h5>
            <p>One player will be drawn <strong>AT RANDOM</strong> to win the cash prize</p>
            <p className="content is-small">
              <strong>
                ✨ THE MORE POINTS YOU HAVE, THE BETTER YOUR ODDS ✨
              </strong>
            </p>
          </div>
        </div>
        <div className="cell">
          <div className="notification">
            <h5 className="">FREE GAME ENTRY</h5>
            <p>The top 40% of points scorers will get auto-enrolled into the next game, TOTALLY FREE</p>
            <p className="content is-small">
              <strong>
                ✅ IF YOU'VE ALREADY JOINED THE NEXT GAME, YOU'LL GET A CREDIT TOP-UP ✅
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className="block">
        <div>1km distance = 1 point</div>
        <div>25m elevation gain = 1 point</div></div>
    </div>
  );
};

export default WaysToWin;
