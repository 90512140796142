import React from "react";

function RoundImage({ imageUrl, alt }) {
  return (
    <div
      style={{
        display: "inline-block",
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        overflow: "hidden",
      }}
    >
      <img src={imageUrl} alt={alt} style={{ width: "100%", height: "100%" }} />
    </div>
  );
}

export default RoundImage;
