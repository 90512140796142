import React from "react";
import ReactDOM from "react-dom/client";
import "bulma/css/bulma.min.css";
import App from "./App";
import { UserProvider } from "./context/UserContext";
import { BetaProvider } from "./context/BetaContext";
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Add other "pages" in which I want to get the User data, within the UserProvider tags
root.render(
  <BetaProvider>
    <UserProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserProvider>
  </BetaProvider>
);
