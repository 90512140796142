import stravaAuthUrl from "./stravaAuthUrl";
import strava_connect from "../assets/btn_strava_connectwith_orange.png"

const StravaConnectModal = ({ active, handleModal }) => {
  const closeModal = () => {
    handleModal();
  };

  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <h1 className="modal-card-title">{`Connect Strava`}</h1>
        </header>
        <section className="modal-card-body">
          <p className="content">
            To need to connect strava before joining a game, so that your
            activities earn you points
          </p>
          <div className="content has-text-centered">
            <a href={stravaAuthUrl}>
              <img src={strava_connect} alt={"Connect with Strava"} />
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default StravaConnectModal;
