import React from "react";
import { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import StravaConnectFailModal from "../components/ModalStravaConnectFail";
import StravaConnectSuccessModal from "../components/ModalStravaConnectSuccess";

// This component is triggered when we hit the /strava/success page, such that we can pull out the auth code from the URL params using useSearchParams
export default () => {
  const [searchParams] = useSearchParams();
  const { token } = useContext(UserContext);
  const [activeStravaConnectFailModal, setStravaConnectFailModal] =
    useState(false);
  const [activeStravaConnectSuccessModal, setStravaConnectSuccessModal] =
    useState(false);

  let navigate = useNavigate();

  const handleStravaConnectSuccessModal = () => {
    setStravaConnectSuccessModal(!activeStravaConnectSuccessModal);
  };

  const handleStravaConnectFailModal = () => {
    setStravaConnectFailModal(!activeStravaConnectFailModal);
  };

  const connectStrava = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const authCode = searchParams.get("code");
    const scope = searchParams.get("scope");

    // TODO There's likely a better way of catching this (i.e. when the user breaks out of the connection before submitting)
    if (!scope) {
      navigate("/");
    }

    if (!scope.includes("activity:read")) {
      console.log("User did not give read access to activities");
      handleStravaConnectFailModal();
    } else {
      const response = await fetch(
        `/api/strava_connect?auth_code=${authCode}`,
        requestOptions
      );

      if (response.status === 200) {
        handleStravaConnectSuccessModal();
      }
    }
  };

  useEffect(() => {
    connectStrava();
  }, []);

  return (
    <>
      <StravaConnectFailModal
        active={activeStravaConnectFailModal}
        handleModal={handleStravaConnectFailModal}
      />
      <StravaConnectSuccessModal
       active={activeStravaConnectSuccessModal}
       handleModal={handleStravaConnectSuccessModal}
      />
      <h1>Connecting to Strava...</h1>
    </>
  );
};
