import React, { useState } from "react";

function PasswordResetForm() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/generate_password_reset_token", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email }),
      });

      // console.log({response})

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
      } else {
        const errorData = await response.json();
        setMessage(errorData.detail);
      }
    } catch (error) {
      setMessage("An error occurred while sending the password reset email.");
      console.error(error);
    }
  };

  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1 className="subtitle has-text-centered">
                Enter your email address to recieve a password reset link
              </h1>
              <div className="box">
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label" htmlFor="email">
                      Enter your email:
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        // placeholder="your@email.com"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control ">
                      <button className="button is-primary" type="submit">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </form>
                {message && <p>{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PasswordResetForm;
