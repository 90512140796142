import React, { createContext, useContext, useState } from 'react';

// Create the BetaContext
const BetaContext = createContext();

// Create a custom hook to use the BetaContext
export const useBeta = () => {
  return useContext(BetaContext);
};

// Create a provider component for the BetaContext
export const BetaProvider = ({ children }) => {
  const [beta, setBeta] = useState(true); // Default value is false

  return (
    <BetaContext.Provider value={{ beta, setBeta }}>
      {children}
    </BetaContext.Provider>
  );
};
