import React from "react";

function PrivacyPolicy() {
  return (
    <div className="container">
      <div className="section">
        <h1 className="title">Privacy Policy</h1>
        <div className="content">
          <p className="is-italic">Updated: 7th May 2024</p>
          <p>
            This Privacy Policy ("Policy") describes how Avalon Research Ltd
            ("we", "us", or "our") collects, uses, and discloses personal
            information when you use The Gradient Games application ("the
            Service").
          </p>
          <h2>1. Information Collection and Use</h2>
          <p>
            <strong>1.1 Personal Information:</strong> We may collect personal
            information from you when you use the Service, including but not
            limited to your name, email address, and activity data.
          </p>
          <p>
            <strong>1.2 Activity Data:</strong> By using the Service, you grant
            us access to your activity data, which is processed for the purpose
            of calculating game points, enhancing your user experience and as
            part of aggregated data product generation.
          </p>

          <h2>2. Data Processing</h2>
          <p>
            <strong>2.1 Purpose:</strong> We process your personal information
            for the purpose of providing and improving the Service, as well as
            for internal research, analysis and development of aggregated data
            products.
          </p>
          <p>
            <strong>2.2 Anonymisation:</strong> If you delete your account, we
            reserve the right to maintain your cycling activity data for
            analytical and research purposes. However, we will anonymise this
            data to protect your privacy.
          </p>

          <h2>3. Information Sharing and Disclosure</h2>
          <p>
            <strong>3.1 Third-Party Services:</strong> We may share your
            personal information with third-party service providers who assist
            us in operating the Service or conducting our business activities.
          </p>
          <p>
            <strong>3.2 Legal Compliance:</strong> We may disclose your personal
            information if required to do so by law or in response to valid
            legal requests, such as court orders or subpoenas.
          </p>

          <h2>4. Data Security</h2>
          <p>
            <strong>4.1 Security Measures:</strong> We implement appropriate
            security measures to protect your personal information against
            unauthorized access, alteration, disclosure, or destruction.
          </p>
          <p>
            <strong>4.2 Data Retention:</strong> We retain your personal
            information for as long as necessary to fulfill the purposes
            outlined in this Policy or as required by law.
          </p>

          <h2>5. Your Rights</h2>
          <p>
            <strong>5.1 Access and Correction:</strong> You have the right to
            access and correct your personal information stored by us. You may
            also request the deletion of your account and associated data.
          </p>

          <h2>6. Changes to this Policy</h2>
          <p>
            <strong>6.1 Policy Updates:</strong> We may update this Policy from
            time to time by posting the revised version on the Service. We
            encourage you to review this Policy periodically for any changes.
          </p>

          <h2>7. Contact Us</h2>
          <p>
            <strong>7.1 Questions:</strong> If you have any questions or
            concerns about this Policy or our data practices, please contact us
            at support@thegradientgames.com.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
