import React from "react";
import "./ModalComponent.css";
import { useNavigate } from "react-router-dom";

const StravaConnectSuccessModal = ({ active, handleModal }) => {
  const modalClass = `modal ${active ? "is-active" : ""}`;
  const contentClass = `modal-content fade-in ${active ? "show" : ""}`;
  let navigate = useNavigate();

  const closeModal = () => {
    handleModal();
    navigate("/");
  };

  return (
    <div className={modalClass}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="section">
        <div className="notification is-success is-light">
          <p>
            Awesome, you've connected your Strava. Make sure you join a game <strong>before</strong> you next go riding, so that you earn points and have a chance of winning the prize.
          </p>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeModal}
      ></button>
    </div>
  );
};

export default StravaConnectSuccessModal;
