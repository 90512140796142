import React from "react";
import { useBeta } from "../context/BetaContext";

const CreditBalanceModal = ({ isOpen, onClose }) => {
  const { beta } = useBeta();
  return (
    <div className={`modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="box">
          <p className="content" style={{ whiteSpace: "pre-line" }}>
            <strong>Credits</strong> are required to enter games. You can
            purchase additional game credits in your{" "}
            <a href="/Profile">Profile</a> section.
          </p>
          <p className="content">
            <strong>Balance</strong> shows the cash amount you have from game
            prizes. This can be used to buy more credits, or can be withdrawn into your
            bank account {beta ? ("(NOT IN BETA)") : null}.
          </p>
          <p className="block has-text-centered">
            <a href="/Profile">Go to Profile</a>
          </p>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    </div>
  );
};

export default CreditBalanceModal;
