import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";

import { useBeta } from "../context/BetaContext";

import DepositModal from "./ModalDeposit";
import InfoModal from "./modalInfo";
import ToggleSwitch from "./ToggleSwitch";

const WalletSection = () => {
  const { setToken, user, fetchUser } = useContext(UserContext);
  const [activeDepositModal, setActiveDepositModal] = useState(false);
  const [activeInfoModal, setActiveInfoModal] = useState(false);
  const { beta } = useBeta();

  const handleDepositModal = () => {
    setActiveDepositModal(!activeDepositModal);
    fetchUser();
  };

  const handleInfoModal = () => {
    setActiveInfoModal(!activeInfoModal);
  };

  useEffect(() => {
    if (!activeDepositModal) {
      // Force a re-render of the component when the modal is closed
      // This could be achieved by setting state or a key
    }
  }, [activeDepositModal]);

  return (
    <>
      <DepositModal
        active={activeDepositModal}
        handleModal={handleDepositModal}
        setErrorMessage={null}
      />
      <InfoModal
        isOpen={activeInfoModal}
        onClose={handleInfoModal}
        text={`Cash withdrawal function not available during Beta Testing`}
      />
      <div className="has-text-centered">
        <div className="block">
          <strong>Wallet 💰</strong>
        </div>
        <div className="notification is-warning is-light">
          Balance shown is <strong>NOT REAL MONEY</strong> during Beta Testing
        </div>
        <div className="columns is-mobile">
          <div className="column has-text-right">
            <div className="content">Game Credits available:</div>
            <div className="content">Auto-enrol in new games:</div>
            <div className="content">Cash balance available:</div>
          </div>
          <div className="column has-text-left">
            <div className="content">{user.credits}</div>
            <ToggleSwitch
              initialChecked={user.auto_enrol}
              endpoint={"user"}
            />
            <div className="content">
              £{user.balance} ⚠️ NOT REAL CASH IN BETA ⚠️
              {/* {beta ? (
                " (not real cash during beta)"
              ):(null)} */}
              {/* <div className="container">
                <p>
                  {" "}
                  <span
                    className="has-text-info is-size-7"
                    onClick={handleInfoModal}
                  >
                    ℹ
                  </span>
                </p>
                <InfoModal
                  isOpen={activeInfoModal}
                  onClose={handleInfoModal}
                  text={`Cash balance is the amount you can withdraw`}
                />
              </div> */}
            </div>
          </div>{" "}
        </div>
        {/* <p>BALANCE: £{user.balance} </p> */}
        {/* <BuyButtonComponent /> */}
        <div className="block m-3">
          <div className="buttons is-centered">
            <p className="control">
              <button
                className="button is-success is-fullwidth"
                onClick={handleDepositModal}
              >
                Get Game Credits
              </button>
            </p>
            <p className="control">
              {beta ? (
                <button className="button is-info" onClick={handleInfoModal}>
                  ⛔️ Withdraw Cash ⛔️
                </button>
              ) : (
                <button className="button is-info"> Withdraw Cash</button>
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletSection;
