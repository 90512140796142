import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';


const SegmentDetails = ({ segment }) => {
    const segmentInfo = [
        ["Distance", `${+(Math.round(segment.distance / 1000 + "e+2") + "e-2")} km`],
        ["Elevation gain", `${segment.elev_gain} m`],
        ["Ave. grade", `${+(Math.round(100 * segment.elev_gain / segment.distance + "e+1") + "e-1")} %`]
    ]

    const segmentUrl = `https://www.strava.com/segments/${segment.segment_id}`

    return (
        <>
            <div className="columns">
                {
                    segmentInfo.map((info) => (
                        <div key={info[0]} className="column is-one-fifth">
                            <SegmentInfo heading={info[0]} content={info[1]} />
                        </div>
                    ))}
                    <div className="column">
                        <Link
                            onClick={() => window.open(segmentUrl, '_blank')}
                            className="button is-warning">
                                View on Strava
                        </Link>
                    </div>
            </div>
        </>
    )
};

function SegmentInfo({ heading, content }) {
    return (
        <>
            <p className="is-size-7">{heading}</p>
            <p className="is-size-5">{content}</p>
        </>
    )
}

export default SegmentDetails