import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
// import { GetUserInfo } from "../utils/getUsersMe";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const GameTile = ({ game }) => {
  const [isActive, setIsActive] = useState(false);
  const [isFuture, setIsFuture] = useState(false);
  let navigate = useNavigate();

  const getGameState = () => {
    if (game.is_active) {
      setIsActive(true);
    } else if (moment().diff(game.start_date) < 0) {
      setIsFuture(true);
    }
  };

  const GameStatusTag = () => {
    if (isActive) {
      return <span className="tag is-primary is-pulled-right">LIVE</span>;
    } else if (isFuture) {
      return (
        <span className="tag is-warning is-pulled-right">COMING SOON</span>
      );
    }
    return <span className="tag is-danger is-pulled-right">ENDED</span>;
  };

  const GameHighlight = () => {
    if (isActive) {
      return (
        <p>
          ⏰ Game <strong>ends</strong> in{" "}
          {-moment().diff(game.end_date, "days") + 1} days ⏰
        </p>
      );
    } else if (isFuture) {
      return (
        <div>
          🚀 Game <strong>begins</strong> in{" "}
          {-moment().diff(game.start_date, "days") + 1} days 🚀
        </div>
      );
    }
    return (
      <>
        {game.winner ? (
          <div>
            🏆 The winner of this game was {game.winner.strava_first_name}{" "}
            {game.winner.strava_last_name} 🏆
          </div>
        ) : null}
      </>
    );
  };

  const handleClick = () => {
    navigate(`/game/${game.id}`);
  };

  useEffect(() => {
    getGameState();
  }, []);

  return (
    <>
      <article
        className="box notification"
        style={{ borderRadius: 20 }}
        onClick={handleClick}
      >
        <GameStatusTag />
        <p className="title is-4">{game.name}</p>
        <div className="block">
          <div className="content is-small">
            {moment(game.start_date).format("Do MMM YYYY")} -{" "}
            {moment(game.end_date).format("Do MMM YYYY")}{" "}
          </div>
        </div>
        <div className="block">
          <p className="has-text-centered">
            <Link className="button is-success" to={`/game/${game.id}`}>
              {/* <button className="button is-success"> */}
              <div>
                <p>Current game prize → £{game.prize}</p>
                <p className="content is-small">⚠️ NOT REAL CASH IN BETA ⚠️</p>
              </div>
              {/* </button> */}
            </Link>
          </p>
        </div>
        <div className="block">
          <div className="content is-small has-text-centered">
            <GameHighlight />
          </div>
        </div>
      </article>
    </>
  );
};

export default GameTile;
