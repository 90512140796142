import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useBeta } from "../context/BetaContext";

const DepositModal = ({ active, handleModal, setErrorMessage }) => {
  const { token } = useContext(UserContext);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isDepositEnabled, setIsDepositEnabled] = useState(false);
  const { beta } = useBeta();

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
    setIsDepositEnabled(true);
  };

  const closeModal = () => {
    handleModal();
  };

  const handleAddCredits = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      `/api/users/buy_credits?num_credits=${selectedAmount}`,
      requestOptions
    );

    if (!response.ok) {
      setErrorMessage("Something went wrong when adding funds");
      handleModal();
    } else {
      console.log("Credits purchased:", selectedAmount);
      handleModal();
      // Open a success dialog
      // handleSuccessModal();
    }
  };

  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card has-text-centered">
        {/* <header className="modal-card-head">
          <h1 className="modal-card-title">{`Join ${game.name}`}</h1>
        </header> */}
        <section className="modal-card-body">
          <div className="content">
            <p>Select the number of game credits you want</p>
            <p className="content is-small has-text-grey">
              NOTE: GAMES TYPICALLY REQUIRE BETWEEN 3 AND 5 CREDITS TO PLAY
            </p>
          </div>
          <div className="buttons has-addons">
            <button
              className={`button ${selectedAmount === 10 ? "is-info" : ""}`}
              onClick={() => handleAmountSelect(10)}
            >
              10 credits
            </button>
            <button
              className={`button ${selectedAmount === 20 ? "is-info" : ""}`}
              onClick={() => handleAmountSelect(20)}
            >
              20 credits
            </button>
            <button
              className={`button ${selectedAmount === 50 ? "is-info" : ""}`}
              onClick={() => handleAmountSelect(50)}
            >
              50 credits
            </button>
          </div>
          <button
            className="button is-primary is-fullwidth"
            onClick={handleAddCredits}
            disabled={!isDepositEnabled}
          >
            {/* Get credits */}
            {beta
              ? "Get credits"
              : selectedAmount
              ? `Buy ${selectedAmount} credits for £${selectedAmount}`
              : "Buy credits"}
          </button>
          <button className="button is-danger is-inverted" onClick={closeModal}>
            Cancel
          </button>
          {/* <div className="content is-small">
            <p>Note: only cash earned from winning can be withdrawn</p>
          </div> */}
        </section>

        {/* <footer className="modal-card-foot">
          <button className="button" onClick={closeModal}>
            Cancel
          </button>
          <button className="button is-primary" onClick={handleJoinGame}>
            Confirm
          </button>
        </footer> */}
      </div>
    </div>
  );
};

export default DepositModal;
