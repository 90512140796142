import React from "react";

import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "./ErrorMessage";
import { UserContext } from "../context/UserContext";
import poweredByStrava from "../assets/api_logo_pwrdBy_strava_horiz_gray.svg";

const LoginRegisterPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [value, setValue] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const fetchRemaining = async (e) => {
    const requestOptions = {
      method: "GET",
    };
    const response = await fetch(
      `/api/users/free_credits_remaining`,
      requestOptions
    );

    if (response.ok) {
      const data = await response.json();
      if (data) {
        setValue(data.value);
        setRemaining(data.remaining);
        setLoaded(true);
      }
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  useEffect(() => {
    fetchRemaining();
  }, []);

  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-two-thirds">
              {!isLogin ? (
                loaded ? (
                  <div className="content">
                    <div className="notification is-success is-light">
                      <p>
                        💰 The next <strong>{remaining}</strong> people who
                        register (and sync with Strava) will recieve{" "}
                        <strong>£{value} worth of game credits</strong> (once
                        we're out of Beta)
                      </p>
                      <p>
                        These credits can be used to play games with real cash
                        rewards
                      </p>
                      <p className="has-text-centered">
                        <strong>👇 Register below to claim yours 👇</strong>
                      </p>
                    </div>
                  </div>
                ) : null
              ) : null}
              <div className="box">
                <h1 className="title has-text-centered">
                  {isLogin ? "Login" : "Register"}
                </h1>

                {isLogin ? (
                  <LoginForm toggleForm={toggleForm} />
                ) : (
                  <RegisterForm toggleForm={toggleForm} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LoginForm = ({ toggleForm }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { setToken } = useContext(UserContext);
  let navigate = useNavigate();

  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };

    const response = await fetch("/api/token", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage(data.detail);
    } else {
      // This updates the token value in the UserContext and we'll get that update everywhere else.
      setToken(data.access_token);
    }
    navigate("/");
  };

  const goToResetPassword = () => {
    navigate("/password-reset-form");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitLogin();
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Login form fields */}
      <div className="field">
        <label className="label">Email</label>
        <div className="control">
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
            autoComplete="email"
            required
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Password</label>
        <div className="control">
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
            autoComplete="current-password"
            required
          />
        </div>
      </div>

      <div className="content">
        Forgotten your password?{" "}
        <a href="#" onClick={goToResetPassword}>
          Reset it here
        </a>
      </div>

      <div className="field">
        <div className="control">
          <button className="button is-primary">Login</button>
        </div>
      </div>

      <div className="content is-italic has-text-danger">{errorMessage}</div>

      <p className="has-text-centered">
        Don't have an account?{" "}
        <a href="#" onClick={toggleForm}>
          Register here
        </a>
      </p>
    </form>
  );
};

const RegisterForm = ({ toggleForm }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [marketingOptIn, setMarketingOptIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setToken } = useContext(UserContext);
  let navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePasswords(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validatePasswords(password, e.target.value);
  };

  const validatePasswords = (password, confirmPassword) => {
    setPasswordsMatch(password === confirmPassword);
  };

  // Event handler for checkbox change
  const handleCheckboxChange = () => {
    setMarketingOptIn(!marketingOptIn); // Toggle the state variable
    console.log(marketingOptIn);
  };

  // Function to actually do the registration of a new user
  const submitRegistration = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password: password,
        marketing: marketingOptIn,
      }),
    };
    console.log(marketingOptIn);

    // Get the response from our Create Users endpoint
    const response = await fetch("/api/users", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage(data.detail);
    } else {
      // Because this changes here, it will trigger the useEffect hook in the
      //  UserContext, which will go ahead and set the new token to local storage
      setToken(data.access_token);
      navigate("/");
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    // TODO: Make this more secure
    if (password.length > 5) {
      submitRegistration();
    } else {
      setErrorMessage("Password must be at least 6 characters long");
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <div className="content has-text-centered">
        <img src={poweredByStrava} alt="" style={{ width: 200, height: 50 }} />
        <div className="block">
          The Gradient Games is powered by Strava. You must have a Strava account to play in the games.
        </div>
      </div>
      {/* Register form fields */}
      <div className="field">
        <label className="label is-size-7">Email</label>
        <div className="control">
          <input
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input"
            autoComplete="email"
            required
          />
        </div>
      </div>

      <div className="field">
        <label className="label is-size-7">Password</label>
        <div className="control">
          <input
            type="password"
            placeholder="Enter Password"
            value={password}
            // onChange={(p) => setPassword(p.target.value)}
            onChange={handlePasswordChange}
            className="input"
            autoComplete="new-password"
            required
          />
        </div>
      </div>

      {/* <div className="field">
        <label className="label is-size-7">Confirm Password</label>
        <div className="control">
          <input
            type="password"
            value={confirmPassword}
            placeholder="Repeat Password"
            onChange={handleConfirmPasswordChange}
            className="input"
            autoComplete="new-password"
            required
          />
        </div>
      </div> */}

      <div className="field">
        <label className="checkbox">
          <input
            type="checkbox"
            checked={marketingOptIn} // Set checked state dynamically
            onChange={handleCheckboxChange} // Handle checkbox change
          />
          {" 👈 tap this if you want to hear about new stuff!"}
        </label>
      </div>

      <ErrorMessage message={errorMessage} />

      <div className="content is-small">
        <p className="is-small">
          <strong>
            By registering, you confirm that you are at least 18 years of age
            and agree to our <a href="/terms">Terms</a> and{" "}
            <a href="/privacy">Privacy Policy</a>
          </strong>
        </p>
      </div>

      <div className="field">
        <div className="control">
          {/* {passwordsMatch ? ( */}
          <button className="button is-primary">Register</button>
          {/* ) : (
            <>
              <button className="button is-primary" disabled>
                Register
              </button>
              <div className="content">
                <i>Passwords don't match</i>
              </div>
            </>
          )} */}
        </div>
      </div>

      <p className="has-text-centered">
        Already have an account?{" "}
        <a href="#" onClick={toggleForm}>
          Login here
        </a>
      </p>
    </form>
  );
};

export default LoginRegisterPage;
