import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";


function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [message, setMessage] = useState("");
  let navigate = useNavigate();


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePasswords(e.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    validatePasswords(password, e.target.value);
  };

  const validatePasswords = (password, confirmPassword) => {
    setPasswordsMatch(password === confirmPassword);
  };

  const token = searchParams.get('token')
  console.log(token)

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/reset-password", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          {
            "secret_token" : token,
            "new_password" : password
          }),
      });

      // console.log({response})

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        navigate("/")
      } else {
        const errorData = await response.json();
        setMessage(errorData.detail);
      }
    } catch (error) {
      setMessage("An error occurred while sending the password reset email.");
      console.error(error);
    }
  };

  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-half">
              <h1 className="subtitle has-text-centered">
                Create a new password
              </h1>
              <div className="box">
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label is-size-7">New Password</label>
                    <div className="control">
                      <input
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        // onChange={(p) => setPassword(p.target.value)}
                        onChange={handlePasswordChange}
                        className="input"
                        autoComplete="new-password"
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label is-size-7">
                      Confirm New Password
                    </label>
                    <div className="control">
                      <input
                        type="password"
                        value={confirmPassword}
                        placeholder="Repeat Password"
                        onChange={handleConfirmPasswordChange}
                        className="input"
                        autoComplete="new-password"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      {passwordsMatch ? (
                        <button className="button is-primary">Submit</button>
                      ) : (
                        <>
                          <button className="button is-primary" disabled>
                            Submit
                          </button>
                          <div className="content">
                            <i>Passwords don't match</i>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </form>
                {message && <p>{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
