import React from "react";
import { useEffect, useState, useContext } from "react";

import { UserContext } from "../context/UserContext";
import Header from "../components/Header";
import TableActive from "../components/TableUserChallenges";
import TableActivities from "../components/TableActivities";
import ProfileSection from "../components/ProfileSection";
import LoginRegisterPage from "../components/LoginRegister";
import WalletSection from "../components/WalletSection";
import DangerSection from "../components/DangerSection";

export default () => {
  const [message, setMessage] = useState("");
  const { token, user, isUserLoaded } = useContext(UserContext);

  const getWelcomeMessage = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch("/api/home", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      console.log("!Something went wrong!");
    } else {
      if (!token) {
        setMessage("Hi, register or login to start the game");
      } else {
        setMessage("Profile");
      }
    }
  };

  const horizontalRuleStyle = {
    height: "2px",
    border: "none",
  };

  const sectionHeadingStyle = {
    fontSize: 24,
    // color: "#4a54f1",
    // textAlign: "center",
    padding: "30px",
  };

  useEffect(() => {
    getWelcomeMessage();
  }, []);

  return (
    <>
      {!token ? (
        <div>
          {/* FIXME this doesn't seem to work if one logs in here.
          It throws an error when getting the number of activities in the user object in TableActivities */}
          <LoginRegisterPage />
        </div>
      ) : (
        <>
          {!isUserLoaded ? (
            <div>Loading</div>
          ) : (
            <div className="columns is-centered">
              <div className="column"></div>
              <div className="column m-5 is-two-thirds">
                <ProfileSection />
                <hr style={horizontalRuleStyle} />
                <WalletSection />
                <hr style={horizontalRuleStyle} />
                {/* <div style={sectionHeadingStyle}>Your activities</div> */}
                <TableActivities />
                <hr style={horizontalRuleStyle} />
                <DangerSection />
              </div>
              <div className="column"></div>
            </div>
          )}
        </>
      )}
    </>
  );
};
