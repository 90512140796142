import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content has-text-centered has-text-grey">
        <p>
          <strong>The Gradient Games.</strong> Let's get the world moving.
        </p>
      </div>
      <div className="columns">
        <div className="column"></div>
        <center className="column">
          <p className="title is-7">ABOUT</p>
          <ul className="content">
            <li>
              <a href="/rules" className="content is-small">
                Rules
              </a>
            </li>
            <li>
              <a href="https://thegradientgames.beehiiv.com/" target="_blank" className="content is-small">
                Blog
              </a>
            </li>
            <li>
              <a href="/faq" className="content is-small">
                FAQ
              </a>
            </li>{" "}
          </ul>
        </center>
        <center className="column">
          <p className="title is-7">SUPPORT</p>
          <ul className="content">
            <li>
              <a href="/contact" className="content is-small">
                Contact
              </a>
            </li>
            <li>
              <a href="/terms" className="content is-small" target="_blank">
                Terms of service
              </a>
            </li>
            <li>
              <a href="/privacy" target="_blank" className="content is-small">
                Privacy policy
              </a>
            </li>
            {/* Add more items as needed */}
          </ul>
        </center>
        <div className="column"></div>
      </div>
    </footer>
  );
};

export default Footer;
