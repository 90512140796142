import React from "react";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import logo from "../assets/logo_line_spaced.png";
import strava_connect from "../assets/btn_strava_connectwith_orange@2x.png";
import "./buttons.css";
import ModalRefer from "./ModalRefer";
import FreeCreditsModal from "./ModalFreeCredits";
import stravaAuthUrl from "./stravaAuthUrl";
import CreditBalanceModal from "./ModalCreditBalance";

const Navbar = () => {
  const { token, setToken, user, setUser, fetchUser, isUserLoaded } =
    useContext(UserContext);
  const [isActive, setisActive] = useState(false);
  const [activeInviteModal, setActiveInviteModal] = useState(false);
  const [activeFreeCreditsModal, setActiveFreeCreditsModal] = useState(false);
  const [activeCreditBalanceModal, setActiveCreditBalanceModal] =
    useState(false);

  const handleInviteModal = () => {
    setActiveInviteModal(!activeInviteModal);
  };

  const handleFreeCreditsModal = () => {
    setActiveFreeCreditsModal(!activeFreeCreditsModal);
  };

  const handleCreditBalanceModal = () => {
    setActiveCreditBalanceModal(!activeCreditBalanceModal);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <nav
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <ModalRefer
        active={activeInviteModal}
        handleModal={handleInviteModal}
        token={token}
        setErrorMessage={null}
      />
      {/* <FreeCreditsModal
        active={activeFreeCreditsModal}
        handleModal={handleFreeCreditsModal}
        setErrorMessage={null}
      /> */}
      <CreditBalanceModal
        isOpen={activeCreditBalanceModal}
        onClose={handleCreditBalanceModal}
      />
      <div className="navbar-brand">
        <a className="navbar-item" href="https://thegradientgames.com">
          <img src={logo} width="28" height="28"></img>
        </a>
        <a
          onClick={() => {
            setisActive(!isActive);
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navMenu"
        className={`navbar-menu ${isActive ? "is-active" : ""}`}
      >
        <div className="navbar-start">
          <a className="navbar-item" href={"/"}>
            Home
          </a>
          <a className="navbar-item" href={"/games"}>
            Games
          </a>
          <a className="navbar-item" href={"/how"}>
            How it works
          </a>
          <a className="navbar-item" href={"/beta"}>
            Beta Testing
          </a>
          {/* <a className="navbar-item" href={"/rules"}>
            Rules
          </a> */}
          {user && user.strava_id ? (
            <a className="navbar-item">
              <button
                className="button is-rounded is-highlight"
                onClick={handleInviteModal}
              >
                Invite friends
              </button>
            </a>
          ) : (
            <>
              {token ? (
                <a className="navbar-item" href={stravaAuthUrl}>
                  <img src={strava_connect}></img>
                </a>
              ) : null}
            </>
          )}
          {/* <a className="navbar-item">
            <button
              className="button is-rounded is-highlight"
              onClick={handleInviteModal}
            >
              Invite friends
            </button>
          </a> */}

          {/* <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            More
                        </a>

                        <div className="navbar-dropdown">
                            <a className="navbar-item">
                                About
                            </a>
                            <a className="navbar-item">
                                Games Rules
                            </a>
                            <a className="navbar-item">
                                FAQs
                            </a>
                            <hr className="navbar-divider" />
                            <a className="navbar-item">
                                Contact
                            </a>
                        </div>
                    </div> */}
        </div>

        <div className="navbar-end">
          {/* {token ? (
            <a
              className="navbar-item"
              href="/get-free-credits"
              // onClick={handleFreeCreditsModal}
            >
              🚀 Get FREE credits
            </a>
          ) : null} */}

          <div className="navbar-item">
            {token && isUserLoaded ? (
              <div
                className="content is-small"
                onClick={handleCreditBalanceModal}
              >
                  <div>Credits: {user.credits}</div>
                  <div>Balance: £{user.balance}</div>
              </div>
            ) : null}
          </div>

          <div className="navbar-item">
            {token ? (
              <div className="buttons">
                <a className="navbar-item" href="/Profile">
                  {user && user.strava_avatar_url ? (
                    <figure className="image is-square is-24x24">
                      <img
                        className="is-rounded"
                        src={user.strava_avatar_url}
                      />
                    </figure>
                  ) : (
                    <p>Profile</p>
                  )}
                </a>
              </div>
            ) : (
              <a className="navbar-item" href={"/"}>
                Log in
              </a>
              // </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
