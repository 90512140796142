import React from "react";

const LoginRegisterModal = ({ isOpen, onClose }) => {
  return (
    <div className={`modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="box has-text-centered">
          <p className="content" style={{ whiteSpace: "pre-line" }}>
            You must be Logged in to join a game
          </p>
          <a className="button is-primary" href="/">
            Login or Register now
          </a>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    </div>
  );
};

export default LoginRegisterModal;
