import React from 'react';
import './ModalComponent.css';

const JoinSuccessModal = ({ active, handleModal }) => {
  const modalClass = `modal ${active ? 'is-active' : ""}`;
  const contentClass = `modal-content fade-in ${active ? 'show' : ''}`;
  
  const closeModal = () => {
    handleModal();
  };

  return (
    <div className={modalClass}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className='section'>
        <div className="notification is-success is-light">
          <p>
            🥳 👏 Congratulations, you've joined the game. Now ride your bike, earn points, and win the prize!
          </p>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeModal}
      ></button>
    </div>
  );
};

export default JoinSuccessModal;
