import React from "react";

function TermsAndConditionsPage() {
  return (
    <div className="container">
      <div className="section">
        <h1 className="title">Terms and Conditions</h1>
        <div className="content">
          <p>
            These terms and conditions ("Terms") govern your use of The Gradient
            Games ("the Service"), operated by Avalon Research Ltd ("the
            Company"). By accessing or using the Service, you agree to be bound
            by these Terms. If you disagree with any part of the Terms, you may
            not access the Service.
          </p>
          <h2>1. Participation</h2>
          <p>
            Participation in the games offered by the Service is subject to the
            following conditions:
          </p>
          <p>
            1.1 Players must be at least 18 years old to participate in the
            games.
          </p>
          <p>
            1.2 Players must create an account and provide accurate and complete
            information during the registration process.
          </p>
          <p>
            1.3 Players must acquire game credits to participate in the games.
            The acquisition of game credits is subject to the payment terms
            specified by the Service.
          </p>
          <h2>2. Game Rules</h2>
          <p>
            2.1 The rules of each game, including the method of earning points,
            will be provided to players within the Service.
          </p>
          <p>
            2.2 Unless otherwise specified, the general rules, published at
            app.thegradientgames.com/rules, shall govern the games
          </p>
          <p>
            2.3 Players must adhere to the rules of each game as specified by
            the Service. Failure to comply may result in disqualification from
            the game and forfeiture of any prizes or points earned.
          </p>
          <h2>3. Prize Winnings</h2>
          <p>
            3.1 Prize winnings are awarded based on the accumulation of points
            during the games.
          </p>
          <p>
            3.2 Players may only withdraw prize winnings if they have purchased
            at least £10 worth of game credits themselves. This requirement
            ensures fair participation and helps prevent abuse of the Service.
          </p>
          <p>
            3.3 Prize winnings may be subject to additional terms and
            conditions, including tax obligations and withdrawal limits, as
            specified by the Service.
          </p>
          {/* TODO Add in something about the purchase of game credits and how they do not represent cash and cannot be withdrawn */}
          <h2>4. Account Termination </h2>
          <p>
            4.1 The Company reserves the right to terminate or suspend accounts
            of players who violate these Terms or engage in fraudulent or
            abusive behaviour.
          </p>
          <p>
            4.2 Players may request the closure of their account at any time,
            subject to any outstanding obligations, including the withdrawal of
            prize winnings.
          </p>
          <h2>5. Limitation of Liability</h2>
          <p>
            5.1 The Company shall not be liable for any direct, indirect,
            incidental, special, or consequential damages arising out of the use
            or inability to use the Service.
          </p>
          <p>
            5.2 The Service is provided "as is" and without warranties of any
            kind, express or implied.
          </p>
          <h2>6. Amendments </h2>
          <p>
            6.1 The Company reserves the right to amend or modify these Terms at
            any time. Any changes will be effective immediately upon posting on
            the Service.
          </p>
          <p>
            6.2 Players are responsible for reviewing the Terms periodically for
            changes. Continued use of the Service after the posting of changes
            constitutes acceptance of the amended Terms.
          </p>
          <h2>7. Governing Law </h2>
          <p>
            7.1 These Terms shall be governed by and construed in accordance
            with the laws of the United Kingdom, without regard to its conflict
            of law provisions.{" "}
          </p>
          <p>
            7.2 Any dispute arising out of or in connection with these Terms
            shall be subject to the exclusive jurisdiction of the courts of the
            United Kingdom.
          </p>
          <p>
            Any questions or concerns regarding these Terms should be directed
            to support@thegradientgames.com.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsPage;
