import React from "react";
import { Link } from "react-router-dom";
import beta from "../assets/beta.jpg";
import Header from "../components/Header";

const BetaTestingPage = () => {
  return (
    <section className="section">
      <div className="container">
        <Header
          title={"🧪 Beta Testing 🧪"}
          subtitle={"Welcome to The Prologue"}
        />
        <div className="columns">
          <div className="column"></div>
          <div className="column is-two-thirds">
            <div className="content">
              <p>
                Welcome to the Prologue, the beta testing programme for The
                Gradient Games. We're excited to have you on board as we work to improve The
                Gradient Games ahead of its full launch.
              </p>
              <p>
                During the testing, you'll get early access to new features and
                updates, and <strong>your feedback will help shape the future of the
                product</strong>.
              </p>
              <div className="block has-text-centered">
                <Link className="button is-primary" to={"/contact"}>
                  Give feedback now
                </Link>
              </div>
              {/* <p>
                As a thank you for being part of The Prologue, you're eligible
                to receive <strong>FREE game credits</strong>, which can be used
                on REAL games with REAL cash rewards (once we go live). Click
                the button below to see what you need to do to unlock your
                credits.
              </p>
              <div className="block has-text-centered">
                <Link className="button is-primary" to={"/get-free-credits"}>
                  Get free credits
                </Link>
              </div> */}
              <p>
                If you encounter any issues or have any feedback to share,
                please don't hesitate to reach out to us via the{" "}
                <a href="/contact">Contact Form</a>
              </p>
            </div>
            {/* <div className="image">
              <img
                src={beta}
                alt="Code with mug saying Life begins at the edge of your comfort zone"
              />
            </div> */}
          </div>
          <div className="column"></div>
        </div>
      </div>
    </section>
  );
};

export default BetaTestingPage;
