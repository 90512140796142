import React from "react";
import moment from "moment";
import { useContext, useState, useEffect } from "react";

import ErrorMessage from "./ErrorMessage";
import NewActivityModal from "./ModalNewActivity";
import { UserContext } from "../context/UserContext";

const TableActivities = () => {
  const { token, user, isUserLoaded } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [activeAccountDeleteModal, setActiveAccountDeleteModal] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  useEffect(() => {
    // Function to sort data by date attribute
    const sorted = [...user.activities].sort(
      (a, b) => new Date(b.completed_at) - new Date(a.completed_at)
    );
    setSortedData(sorted);
  }, [token]);

  const renderTableData = () => {
    return currentItems.map((activity, index) => (
      <tr key={activity.id}>
        <td>
          <a
            href={"https://www.strava.com/activities/" + activity.activity_strava_id}
            target="_blank"
          >
            {activity.name}
          </a>
        </td>
        <td>{activity.sport_type}</td>
        <td>{moment(activity.completed_at).format("Do MMM YYYY")}</td>
        <td>{(activity.distance / 1000).toFixed(1)}</td>
        <td>{activity.total_elevation_gain}</td>
      </tr>
    ));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //   const handleModal = () => {
  //     setActiveModal(!activeModal);
  //     getActivities();
  //   };

  const handleAccountDeleteModal = () => {
    setActiveAccountDeleteModal(!activeAccountDeleteModal);
  };

  return (
    <>
      {/* <AccountDeleteModal
        active={activeAccountDeleteModal}
        handleModal={handleAccountDeleteModal}
        token={token}
        setErrorMessage={setErrorMessage}
      /> */}
      {/* <NewActivityModal
        active={activeModal}
        handleModal={handleModal}
        token={token}
        setErrorMessage={setErrorMessage}
      /> */}
      {/* <button
                className="button mb-5 is-fullwidth is-primary"
                onClick={() => setActiveModal(true)}
            > Add Activity
            </button> */}
      <ErrorMessage message={errorMessage} />
      <div className="has-text-centered">
        <div className="block m-3">
          <strong>Activities 🚲</strong>
        </div>
      </div>
      {isUserLoaded ? (
        <>
          {user.activities.length < 1 ? (
            <div className="has-text-centered">No activities to display</div>
          ) : (
            <>
              <table className="table is-fullwidth">
                <thead className="is-size-7">
                  <tr>
                    <th>Activity</th>
                    <th>Type</th>
                    <th>Date completed</th>
                    <th>Distance (km)</th>
                    <th>Elevation gain (m)</th>
                  </tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </table>
              <nav
                className="pagination is-centered is-small"
                role="navigation"
                aria-label="pagination"
              >
                <ul className="pagination-list">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li key={index}>
                      <button
                        className={`pagination-link ${
                          currentPage === index + 1 ? "is-current" : ""
                        }`}
                        aria-label={`Goto page ${index + 1}`}
                        onClick={() => handlePageClick(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </>
          )}
        </>
      ) : (
        <p>Loading activities...</p>
      )}
    </>
  );
};

export default TableActivities;
