import React from "react";
import Navbar from "./Navbar";

const Header = ({ title, subtitle }) => {
  return (
    <>
      <section className="hero">
        <div className="hero-body has-text-centered">
          <h1 className="title is-size-1">{title}</h1>
          <h2 className="subtitle is-4">{subtitle}</h2>
        </div>
      </section>
    </>
  );
};
export default Header;
