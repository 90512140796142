import React, { createContext, useContext, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Profile2 from "./pages/Profile2";
import StravaSuccess from "./pages/StravaSuccess";
import Game from "./pages/Game";
import Footer from "./components/Footer";
import FAQPage from "./pages/FAQ";
import RulesPage from "./pages/Rules";
import PasswordResetForm from "./pages/PasswordResetForm";
import ResetPassword from "./pages/ResetPassword";
import TermsAndConditionsPage from "./pages/Terms";
import PrivacyPolicy from "./pages/Privacy";
import ContactUs from "./pages/Contact";
import BetaTestingPage from "./pages/Beta";
import GamesPage from "./pages/Games";
import { useBeta } from "./context/BetaContext";
import FreeCreditsPage from "./pages/FreeCredits";
import HowItWorksPage from "./pages/HowItWorks";

const App = () => {
  const { beta, setBeta } = useBeta(true);

  return (
    <>
      <Navbar></Navbar>
      <>
        {beta ? (
          <section className="block">
            <div className="notification is-warning is-light has-text-centered">
              🧪 The Gradient Games is currently in BETA TESTING → {" "}
              <a href="/beta">Learn more</a> 🧪
            </div>
          </section>
        ) : null}
      </>

      <Routes>
        <Route exact element={<Home />} path="/" />
        <Route exact element={<Profile />} path="/profile" />
        <Route exact element={<Profile2 />} path="/profile2" />
        <Route exact element={<StravaSuccess />} path="/strava/success" />
        <Route exact element={<GamesPage />} path="/games" />
        <Route element={<Game />} path="/game/:slug" />
        <Route element={<FAQPage />} path="/faq" />
        <Route exact element={<RulesPage />} path="/rules" />
        <Route
          exact
          element={<PasswordResetForm />}
          path="/password-reset-form"
        />
        <Route exact element={<ResetPassword />} path="/reset-password" />
        <Route exact element={<TermsAndConditionsPage />} path="/terms" />
        <Route exact element={<PrivacyPolicy />} path="/privacy" />
        <Route exact element={<ContactUs />} path="/contact" />
        <Route exact element={<BetaTestingPage />} path="/beta"/>
        {/* <Route exact element={<FreeCreditsPage />} path="/get-free-credits" /> */}
        <Route exact element={<HowItWorksPage />} path="/how" />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
