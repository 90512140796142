import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

const ModalRefer = ({ active, handleModal, token, setErrorMessage }) => {
  const closeModal = () => {
    handleModal();
  };

  const inviteMessage = "Hey, check out The Gradient Games! 🚲💰";
  const inviteUrl = "https://thegradientgames.com";

  const { user } = useContext(UserContext);

  // TODO handle invite request...
  const handleInvite = async (e) => {
    return null;
  };

  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card has-text-centered">
        {/* <header className="modal-card-head"> */}
        {/* <h1 className="modal-card-title">Games are better with friends</h1> */}
        {/* </header> */}
        <section className="modal-card-body">
          <p className="content">
            <strong>Games are always better with friends</strong>
          </p>
          <p className="content">
            <em>Get them in on the action and let's get the world moving!</em>
          </p>
        </section>

        <footer className="modal-card-foot">
          {/* <button className="button" onClick={closeModal}>
            Cancel
          </button> */}
          <WhatsappShareButton
            url={inviteUrl}
            title={inviteMessage}
            className="m-2"
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <FacebookShareButton
            url={inviteUrl}
            hashtag="#cycling"
            className="m-2"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton
            url={inviteUrl}
            title={inviteMessage}
            hashtags={["cycling", "motivation", "thegradientgames"]}
            className="m-2"
          >
            <XIcon size={32} round={true} />
          </TwitterShareButton>
        </footer>
      </div>
    </div>
  );
};

export default ModalRefer;
