import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";

const JoinGameModal = ({
  game,
  active,
  handleModal,
  token,
  setErrorMessage,
  handleSuccessModal,
}) => {
  const closeModal = () => {
    handleModal();
  };

  const { user } = useContext(UserContext);

  const handleJoinGame = async (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(`/api/games/join/${game.id}`, requestOptions);

    if (!response.ok) {
      setErrorMessage("Something went wrong when adding an activity");
      handleModal();
    } else {
      handleModal();
      // Open a success dialog
      handleSuccessModal();
    }
  };

  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <h1 className="modal-card-title">{`Join ${game.name}`}</h1>
        </header>
        <section className="modal-card-body">
          <p className="content">
            This game costs {game.credits} credits to enter, which will be
            deducted from your balance
          </p>
          <div className="notification is-info is-light mt-4 content has-text-centered">
            <p>
              <strong>
                🏆 The game prize is currently £{game.prize.toFixed(2)}
              </strong>
            </p>
          </div>
          <p>
            <em>
              This game has a minimum prize of £{game.min_prize}, which will
              increase with each new player, once more than{" "}
              {Math.ceil(game.min_prize / (game.credits * game.contribution))}{" "}
              players have joined
            </em>
          </p>
        </section>

        <footer className="modal-card-foot">
          <div className="buttons">
            <button className="button is-danger is-outlined" onClick={closeModal}>
              Cancel
            </button>
            <button className="button is-primary" onClick={handleJoinGame}>
              Confirm
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default JoinGameModal;
