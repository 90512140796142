import React, { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import AccountDeleteModal from "./ModalAccountDelete";

const DangerSection = () => {
  const { token, setToken, fetchUser, user, isUserLoaded } = useContext(UserContext);
  const [activeDeleteModal, setActiveDeleteModal] = useState(false);
  let navigate = useNavigate();

  const handleLogout = () => {
    setToken(null);
    navigate("/");
  };

  const stravaDisconnect = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/strava_disconnect", requestOptions);
    const response_data = response.json();
    if (response.status === 200) {
      fetchUser();
    } else {
      // TODO Make this more robust with an error message
      console.log("failed to disconnect user from Strava");
    }
  };

  const handleDeleteAccount = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/user", requestOptions);
    const response_data = response.json();
    if (response.status === 200) {
      setToken(null);
      // TODO return the login screen
    } else {
      // TODO Make this more robust with an error message
      console.log("failed to delete user");
    }
  };

  const handleDeleteModal = () => {
    setActiveDeleteModal(!activeDeleteModal);
  };

  return (
    <div>
      {/* <AccountDeleteModal
        active={activeDeleteModal}
        handleModal={handleDeleteModal}
        token={token}
        handleDeleteAccount={handleDeleteAccount}
        setErrorMessage={""}
      /> */}
      <div className="has-text-centered">
        <div className="block m-3">
          <strong>Danger zone 🚨</strong>
        </div>
      </div>
      <div className="has-text-centered">
        <div className="block">
          <button className="button is-danger is-outlined" onClick={handleLogout}>
            Logout
          </button>
        </div>
        {isUserLoaded && user.strava_id ? (
          <div className="block">
            <button className="button is-ghost" onClick={stravaDisconnect}>
              Disconnect Strava
            </button>
          </div>
        ) : null}
        {/* <div className="block">
          <button
            className="button is-inverted is-danger"
            onClick={handleDeleteModal}
          >
            Delete Account
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default DangerSection;
