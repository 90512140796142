import React from "react";
import { useContext, useState, useEffect } from "react";
import ErrorMessage from "./ErrorMessage";
import { UserContext } from "../context/UserContext";
import InfoModal from "./modalInfo";

const TablePlayerStats = ({ gameId, userId }) => {
  const { token } = useContext(UserContext);
  const [game, setGame] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [numRefund, setNumRefund] = useState(0);
  const [activePowerUpModal, setActivePowerUpModal] = useState(false);
  const [activePointsModal, setActivePointsModal] = useState(false);
  const [activeRefundModal, setActiveRefundModal] = useState(false);

  const getGame = async (gameId) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(`/api/games/${gameId}`, requestOptions);
    if (!response.ok) {
      setErrorMessage("Something went wrong getting Challenge results");
    } else {
      const gameData = await response.json();
      // console.log(gameData)

      // Sort by points (descending) and, if points are the same, bu "joined_at" datetime (ascending)
      gameData.user_links.sort((a, b) => {
        if (b.points !== a.points) {
          return b.points - a.points;
        } else {
          const dateA = new Date(a.joined_at);
          const dateB = new Date(b.joined_at);
          return dateA - dateB;
        }
      });

      setGame(gameData);
      setNumRefund(Math.ceil(gameData.user_links.length * gameData.no_sweats));
      setLoaded(true);
    }
  };

  const RefundCount = ({ idx }) => {
    if (idx < numRefund) {
      return <td>✅</td>;
    } else {
      return <td></td>;
    }
  };

  const handlePowerUpModal = (getter, setter) => {
    setActivePowerUpModal(!activePowerUpModal);
  };

  const handlePointsModal = () => {
    setActivePointsModal(!activePointsModal);
  };

  const handleRefundModal = () => {
    setActiveRefundModal(!activeRefundModal);
  };

  useEffect(() => {
    getGame(gameId);
  }, []);

  return (
    <>
      <ErrorMessage message={errorMessage} />
      <InfoModal
        isOpen={activePowerUpModal}
        onClose={handlePowerUpModal}
        text={`Power-ups are multipliers of points accumulation. If a player has a power up of 2, then they earn 2x points for their activities.\n\n Players can earn Power-Ups through referrals and continuous game-play streaks.`}
      />
      <InfoModal
        isOpen={activePointsModal}
        onClose={handlePointsModal}
        text={`Points are earned from activities completed while a game you've joined is active. The more points you have, the higher your odds of winning the game prize`}
      />
      <InfoModal
        isOpen={activeRefundModal}
        onClose={handleRefundModal}
        text={`The top 40% of points scorers will be auto-enrolled into the next game, FREE of charge. If you have already joined the next game, your credit balance will get topped-up`}
      />
      {loaded ? (
        <>
          {game.user_links.length ? (
            <table className="table is-fullwidth is-striped">
              <thead className="is-size-7">
                <tr>
                  <th>PLAYER</th>
                  {/* TODO Reintroduce power-ups at some point */}
                  {/* <th>
                    <div className="container">
                      <p>
                        {" "}
                        <span
                          className="has-text-info is-underlined"
                          onClick={handlePowerUpModal}
                        >
                          POWER-UPS
                        </span>
                      </p>
                    </div>
                  </th> */}
                  <th>DISTANCE (km)</th>
                  <th>ELEVATION (m)</th>
                  <th><div className="container">
                      <p>
                        {" "}
                        <span
                          className="has-text-info is-underlined"
                          onClick={handlePointsModal}
                        >
                          POINTS
                        </span>
                      </p>
                    </div></th>
                  <th><div className="container">
                      <p>
                        {" "}
                        <span
                          className="has-text-info is-underlined"
                          onClick={handleRefundModal}
                        >
                          FREE ENTRY?
                        </span>
                      </p>
                    </div></th>
                </tr>
              </thead>
              <tbody>
                {game.user_links.map((player, index) => (
                  <tr key={player.user.id}>
                    <td>
                      <a
                        href={
                          "https://www.strava.com/athletes/" + player.user.strava_id
                        }
                        target="_blank"
                      >
                        {userId==player.user.id ? "✨ " : null}
                        {player.user.strava_first_name}{" "}
                        {player.user.strava_last_name}
                        {game.winner && game.winner.id == player.user.id
                          ? " 🏆"
                          : ""}
                      </a>
                    </td>
                    {/* A players nominal "power-up" should be 0, even though in the backend we start at 1
                    for the purposes of simply using it as a factor to multiply nominal points earned */}
                    {/* <td>{player.user.points_factor}</td> */}
                    <td>{(player.distance / 1000).toFixed(1)}</td>
                    <td>{~~player.elevation_gain}</td>
                    <td>{player.points}</td>
                    <RefundCount idx={index} />
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="content has-text-centered is-italic">
              Leaderboard will show here when at least one player has joined...
            </div>
          )}
        </>
      ) : (
        <p>Loading</p>
      )}
    </>
  );
};

export default TablePlayerStats;
