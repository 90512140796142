import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import beta from "../assets/beta.jpg";
import Header from "../components/Header";
import WaysToWin from "../components/WaysToWin";

const HowItWorksPage = () => {
  const topContainer = useRef();
  const CellBox = ({ title, subtitle, content, image }) => {
    return (
      <div className="notification">
        <div>
          <div className="block">
            <p className="title">{title}</p>
          </div>
          <p className="subtitle">{subtitle}</p>
          <p className="">{image}</p>
          {content}
        </div>
      </div>
    );
  };

  useEffect(() => {
    topContainer.current.scrollIntoView({ block: "end", behavior: "smooth" });
  });

  return (
    <section className="section">
      <div ref={topContainer} />
      <div className="container">
        <Header
          title={"How does it work?"}
          subtitle={"Your quick guide to The Gradient Games"}
        />
        <div className="columns">
          <div className="column"></div>
          <div className="column is-two-thirds">
            <div className="content">
              <p>
                New to the games? Or just need a quick refresher? Here's a quick
                look at how the games work and what you can win
              </p>
              <div class="grid is-col-min-10 has-text-centered">
                <div class="cell">
                  <CellBox
                    title="STEP 1"
                    subtitle="Use Game Credits to Join a Game"
                    content={
                      <div className="notification is-info is-light">
                        {" "}
                        Credits are like currency in the Games{" "}
                      </div>
                    }
                  />
                </div>
                <div class="cell">
                  <CellBox
                    title="STEP 2"
                    subtitle="Ride your bike to earn points"
                    content={
                      <div className="notification is-warning is-light">
                        {" "}
                        You earn points for distance & elevation{" "}
                      </div>
                    }
                  />
                </div>
                <div class="cell">
                  <CellBox
                    title="STEP 3"
                    subtitle="When the game ends, the winners are selected"
                    content={
                      <div className="notification is-success is-light">
                        {" "}
                        See below for "2 Ways to Win"{" "}
                      </div>
                    }
                  />
                </div>
              </div>
              <hr />
              <section className="section">
                <WaysToWin />
              </section>

              <div className="block has-text-centered">
                <Link className="button is-primary" to={"/games"}>
                  CHECK OUT THE GAMES
                </Link>
              </div>
            </div>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksPage;
