import React, { createContext, useContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("UserAccessToken"));
    // const [user, setUser] = useState(localStorage.getItem("UserInformation"))
    const [user, setUser] = useState()
    const [isUserLoaded, setIsUserLoaded] = useState(false)

    const fetchUser = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };
        const response = await fetch("/api/users/me", requestOptions);
        if (!response.ok) {
            setToken(null);
            setUser(null);
        }
        const data = await response.json()
        localStorage.setItem("UserAccessToken", token);
        // localStorage.setItem("UserInformation", data);

        // I seem to be able to use this in the Home component, where I have it as
        //  part of the welcome message, but not in the Table components 
        setUser(data)
        setIsUserLoaded(true)
    };

    // This will run both when the component "mounts" AND when the state of the
    //  thing in the [] at the end of the function changes. So, in our case,
    //  it'll fetch the user information, and update the user's State whenever
    //  the token changes. We're ALSO updating the token value, since that might
    //  be different
    useEffect(() => {
        console.log("Triggering the useEffect hook in the UserProvider")
        fetchUser();
    }, [token]);

    // Use a Provider to pass the current context value to other parts of the app.
    //  Any component can read it, no matter how deep it is in the app's tree.
    //  In this instance, we're passing the value of the token and its setter function
    return (
        // <UserContext.Provider value={[token, setToken, user, setUser]}>
        <UserContext.Provider value={{token, setToken, user, setUser, isUserLoaded: isUserLoaded, fetchUser}}>
            {props.children}
        </UserContext.Provider>
    );
};

// ChatGPT suggests I also include this and then use the "useUser" hook in different components/pages to get the user info, e.g. const user = useUser();
// export const useUser = () => {
//     const context = useContext(UserContext);
//     if (!context) {
//         throw new Error('useUser must be used within a UserProvider');
//     }
//     return context;
// };