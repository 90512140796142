// FAQPage.js

import React from "react";
import "bulma/css/bulma.min.css";
import "./FAQPage.css"; // Custom CSS file for additional styling
import Header from "../components/Header";

const FAQPage = () => {
  return (
    <>
      <Header title={"Frequently Asked Questions"} />
      <div className="columns">
        <div className="column"></div>
        <div className="column is-two-thirds">
          <section className="section pt-0">
              <div className="content">
                <details className="faq-item">
                  <summary className="question">
                    👍 Which activities earn game points?
                  </summary>
                  <p className="answer">
                    Only activities that were completed (i.e. both started and
                    ended) and uploaded to Strava while a game, that you are a
                    player in, is active, earn points.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    💵 How much does it cost to enter a game?
                  </summary>
                  <p className="answer">
                    The cost to play depends on the game, but generally between
                    3-5 game credits (which currently cost £1 each). Check the
                    details of the specific game you are interested in, for the
                    cost to enter.
                  </p>
                </details>

                <details className="faq-item">
                  <summary className="question">🥇 What can I win?</summary>
                  <p className="answer">
                    The amount the winner gets depends on the number of players
                    entered and the cost to enter (in game credits). The cash
                    reward equates to 40% of entry credit value with at least
                    the top 40% of points scorers getting their games credits
                    back.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    🏧 Can I withdraw my balance?
                  </summary>
                  <p className="answer">
                    Yes, you can withdraw your balance (which comes from game
                    winnings), but only after depositing the minimum deposit
                    amount (currently £10). Note, you cannot withdraw the value
                    of your game credits in cash, these can only be used to join
                    games.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    👀 What happens to the portion of entry credits that don’t
                    make up the prize/refund pool?
                  </summary>
                  <p className="answer">
                    The remainder of credits will go to a combination of product
                    improvements & maintenance, and charitable donations.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    🔋 Can I use an eBike?{" "}
                  </summary>
                  <p className="answer">
                    Yes, absolutely. Everyone is welcome to play. While we do
                    acknowledge the level of exurtion for eBiking may be less
                    for some, for others it is their only feasible way to ride,
                    so it counts just the same.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    🎮 Do virtual (indoor) rides count?{" "}
                  </summary>
                  <p className="answer">
                    They sure do. Riding indoors is still riding, it's still
                    hard, and you're more than welcome into the Games.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    🔨 Do "manual" activities count?
                  </summary>
                  <p className="answer">
                    Unfortunately, becuase there's no way to verify the
                    integrity of manual activities, they won't earn points in
                    the games.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    🔁 Can I submit the same activity multiple times?
                  </summary>
                  <p className="answer">
                    No. Absolutely not. That would be madness. Don't do it, it's
                    cheating. Thank you for your understanding.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    🏆 What happens if I win a game?{" "}
                  </summary>
                  <p className="answer">
                    First off, congratulations 🥳 We will be in touch to make
                    you aware that you've won. You will see an increase in your
                    "balance" amount.
                  </p>
                </details>
                {/* <details className="faq-item">
              <summary className="question">
                🫣 If I win a game, can I remain anonymous?
              </summary>
              <p className="answer">
                Sharing success and showing others that real people are winning
                real rewards is one of our best tools for proving value and
                authenticity to other players. As such, it is currently a
                requirement for us to share details of game outcomes. If this
                doesn't sit well with you, we'd love to discuss it so please get
                in touch 🙂
              </p>
            </details> */}
                <details className="faq-item">
                  <summary className="question">
                    🙋 Can I join a game while it's in progress?
                  </summary>
                  <p className="answer">
                    Yes, certainly. But please note that only activities
                    completed and submitted AFTER joining and during the game's
                    active period will earn game points.
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    ❌ Can I leave a game part way through?
                  </summary>
                  <p className="answer">
                    We hope you won't, but yes it is possible to leave a game
                    part way through and receive a refund in the form of credit
                    towards another game in the future. Please note, however, if
                    you were to rejoin the game after leaving, any activities
                    you had submitted prior to leaving would not be counted (see{" "}
                    <a href="/rules">Rule #1</a>).
                  </p>
                </details>
                <details className="faq-item">
                  <summary className="question">
                    🕵🏾 Do "Private" activities count in the Games?
                  </summary>
                  <p className="answer">
                    Private activities (i.e. those with "visibility" set to
                    "Only you") are not considered part of the Game. The reason
                    for this is so that it is possible to verify an activity for
                    legitimacy, if required. We're sure you would agree, losing
                    out to someone who had "ridden" 1,000km in their car
                    throughout the week, and hiding those activities through
                    privacy, would not be fair.
                  </p>
                </details>
              </div>
              {/* Add more sections and FAQ items as needed */}
          </section>
        </div>
        <div className="column"></div>
      </div>
    </>
  );
};

export default FAQPage;
