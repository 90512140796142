import React from "react";
import "./ModalComponent.css";
import { useNavigate } from "react-router-dom";

const StravaConnectFailModal = ({ active, handleModal }) => {
  const modalClass = `modal ${active ? "is-active" : ""}`;
  const contentClass = `modal-content fade-in ${active ? "show" : ""}`;

  let navigate = useNavigate();

  const closeModal = () => {
    handleModal();
    navigate("/");
  };

  return (
    <div className={modalClass}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className={contentClass}>
        <div className="box content">
          <p className="has-text-centered">
            <strong>⚠️ WARNING ⚠️</strong>
          </p>
          <p>
            You must check the "<i>View data about your activities</i>" box.</p>
          <p>
            Without this,{" "}
            <strong>
              we can't fetch information about your rides and you won't earn any
              points
            </strong>
          </p>
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={closeModal}
      ></button>
    </div>
  );
};

export default StravaConnectFailModal;
