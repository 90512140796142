import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../context/UserContext";
import Header from "../components/Header";
import GameTile from "../components/GameTile";
import DepositModal from "../components/ModalDeposit";

const GamesPage = () => {
  const [gamesPast, setGamesPast] = useState(null);
  const [gamesActive, setGamesActive] = useState(null);
  const [gamesFuture, setGamesFuture] = useState(null);
  const [loadedGames, setLoadedGames] = useState(false);
  const { token, user } = useContext(UserContext);
  const currentDate = new Date();
  const topContainer = useRef();

  const getGames = async () => {
    const requestOptionsGame = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const num_games = 10;
    const responseGame = await fetch(
      `/api/games?skip=0&limit=${num_games}`,
      requestOptionsGame
    );
    const dataGame = await responseGame.json();
    if (!responseGame.ok) {
      console.log("Problem getting games");
    } else {
      dataGame.sort((a, b) => {
        if (new Date(a.end_date) !== new Date(b.end_date)) {
          const dateA = new Date(a.end_date);
          const dateB = new Date(b.end_date);
          return dateA - dateB;
        } else {
          const dateA = new Date(a.start_date);
          const dateB = new Date(b.start_date);
          return dateA - dateB;
        }
      });

      setGamesPast(
        dataGame.filter((obj) => {
          const endDate = new Date(obj.end_date);
          return endDate < currentDate;
        }).reverse()
      );

      setGamesActive(
        dataGame.filter((obj) => {
          const startDate = new Date(obj.start_date);
          const endDate = new Date(obj.end_date);
          return startDate <= currentDate && endDate > currentDate;
        })
      );

      setGamesFuture(
        dataGame.filter((obj) => {
          const startDate = new Date(obj.start_date);
          return startDate > currentDate;
        })
      );

      setLoadedGames(true);
    }
  };

  useEffect(() => {
    // getWelcomeMessage();
    getGames();
    topContainer.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }, [user]);

  return (
    <section className="section">
      <div ref={topContainer} />
      <div className="container">
        <Header
          title={"🏁 Games 🏁"}
          subtitle={
            "Ride your bike to earn points and increase your odds of winning REAL cash"
          }
        />
        <div className="columns">
          <div className="column"></div>
          <div className="column is-two-thirds">
            <section className="section">
              {token && user ? (
                <div className="notification has-text-centered">
                  <strong className="block">
                    You currently have {user.credits} credits
                  </strong>
                  <div className="content is-small">
                    USE CREDITS TO JOIN GAMES
                  </div>
                </div>
              ) : null}
            </section>
            <section className="section">
              <h1 className="subtitle is-3 has-text-centered">Active Games</h1>
              {loadedGames ? (
                <>
                  {!gamesActive | (gamesActive.length == 0) ? (
                    <div className="has-text-centered">
                      No games available at present
                    </div>
                  ) : (
                    <div className="grid">
                      <div className="cell">
                        {gamesActive.slice(0, 100).map((id, index) => (
                          <GameTile game={id} />
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <p className="has-text-centered">Fetching active games...</p>
              )}
            </section>
            <section className="section">
              <h1 className="subtitle is-3 has-text-centered">
                Upcoming Games
              </h1>
              {loadedGames ? (
                <>
                  {gamesFuture.length == 0 ? (
                    <div className="has-text-centered">
                      No future games planned at present
                    </div>
                  ) : (
                    <div className="grid">
                      <div className="cell">
                        {gamesFuture.slice(0, 100).map((item, index) => (
                          <GameTile game={item} />
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <p className="has-text-centered">Fetching future games...</p>
              )}
            </section>
            <section className="section">
              <h1 className="subtitle is-3 has-text-centered">
                Previous Games
              </h1>
              {loadedGames ? (
                <>
                  {gamesPast.length == 0 ? (
                    <div className="has-text-centered">
                      No Previous games have occured
                    </div>
                  ) : (
                    <div className="grid">
                      <div className="cell">
                        {gamesPast.slice(0, 100).map((item, index) => (
                          <GameTile game={item} />
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <p className="has-text-centered">Fetching previous games...</p>
              )}
            </section>
          </div>
          <div className="column"></div>
        </div>
      </div>
    </section>
  );
};

export default GamesPage;
