import React from "react";
import { useEffect, useState, useContext } from "react";

import { UserContext } from "../context/UserContext";
import ProfileSection from "../components/ProfileSection";

export default () => {
  const [token] = useContext(UserContext);


  useEffect(
    () => {
    }, []
  );

  return (
    <>
      <div className="columns">
        <div className="column"></div>
        <div className="column m-5 is-two-thirds">
          {
            !token ? (
              <div className="div">
                Hello World!
              </div>
            ) : (
              <div className="div">
                <ProfileSection />
              </div>
            )
          }
        </div>
        <div className="column"></div>
      </div>
    </>
  )
}
