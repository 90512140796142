import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [sent, setSent] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: name, email: email, message, message }),
      });

      // console.log({response})

      if (response.ok) {
        const data = await response.json();
        setResponse(data.message);
        setSent(true);
      } else {
        const errorData = await response.json();
        setResponse(errorData.detail);
      }
    } catch (error) {
      setResponse("An error occurred while sending the password reset email.");
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="section">
        <>
          {sent ? (
            <>
              <h1 className="title">Thanks for Contacting Us!</h1>
              <div className="content">
                <p>
                  We appreciate you reaching out. We'll get back in touch with
                  you as quickly as possible.
                </p>
              </div>
            </>
          ) : (
            <>
              <h1 className="title">Contact Us</h1>
              <div className="content">
                <p>
                  Please contact us at The Gradient Games, using the form below.
                  Or you can email us directly at support@thegradientgames.com.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label">Name</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        placeholder="Your name"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="email">
                      Email
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type="email"
                        placeholder="Your email address"
                        value={email}
                        onChange={handleEmailChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">Message</label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        placeholder="Your message"
                        value={message}
                        onChange={handleMessageChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button className="button is-primary" type="submit">
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default ContactUs;
