import React from "react";
import { useContext, useState, useEffect } from "react";

import { UserContext } from "../context/UserContext";
import RoundImage from "./Avatar";
import ErrorMessage from "./ErrorMessage";
// import BuyButtonComponent from "./BuyButton";

const ProfileSection = () => {
  const { token, setToken, user, isUserLoaded } = useContext(UserContext);
  // const [user, setUser] = useState(null);
  // const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogout = () => {
    setToken(null);
  };

  useEffect(() => {}, [user]);

  return (
    <>
      <ErrorMessage message={errorMessage} />
      <div className="has-text-centered">
        <div className="block">
          <strong className="content ">
            {user.strava_first_name} {user.strava_last_name}
          </strong>
        </div>
        {user.strava_avatar_url ? (
          <div className="block">
            <RoundImage
              imageUrl={user.strava_avatar_url}
              alt="Avatar image fetched from Strava"
            />
          </div>
        ) : (
          <div className="title is-3">Account</div>
        )}
      </div>

      <div className="content">
        {/* FIXME This throws an error if a user logs in on the profile page. user.activities is "undefined", which should never be the case
        <p>ACTIVITES (all-time): {user.activities.length}</p>
        <p>
          DISTANCE (all-time):{" "}
          {Math.round(
            user.activities.reduce(
              (accumulator, currentItem) =>
                accumulator + currentItem["distance"],
              0
            ) / 1000
          )}{" "}
          km
        </p> */}
      </div>
    </>
  );
};

export default ProfileSection;
