import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
// import BuyButtonComponent from "./BuyButton";

const InsufficientFundsModal = ({
  game,
  active,
  handleModal,
  token,
  setErrorMessage,
}) => {
  let navigate = useNavigate();
  const closeModal = () => {
    handleModal();
  };

  const { user } = useContext(UserContext);

  const goToProfile = () => {
    navigate("/Profile");
  };

  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <h1 className="modal-card-title">
            🥲 Uh oh! You have insufficient credits for this game!
          </h1>
        </header>
        <section className="modal-card-body">
          <p className="content">
            Add credits from your Profile and join other cyclists for your chance to win
          </p>
        </section>

        <footer className="modal-card-foot">
          <div className="field is-grouped">
          <button className="button is-danger" onClick={closeModal}>
            Cancel
          </button>
          {/* <BuyButtonComponent /> */}
          {/* TODO make this do something... */}
          <button className="button is-primary" onClick={goToProfile}>
            Go to Profile
          </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default InsufficientFundsModal;
